<template>
  <v-dialog
    v-model="shown"
    width="700"
    content-class="ma-0"
    @click:outside="dialogClose"
  >
    <v-card>
      <v-btn
        fab
        plain
        absolute
        top
        right
        color="white"
        style="top: 28px"
        @click="dialogClose"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-title class="py-10 mb-10 primary">
        <template v-if="editing">
          <h2 class="white--text">Update</h2>
          <p class="white--text">{{ targetEvent.description }}</p>
        </template>
        <h2 v-else class="white--text">Add New Event</h2>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
              <v-card>
                <v-card-text>
                  <TagSelector v-model="eventSecrets.tags" mode="event" />
                  <TagSelector v-model="eventSecrets.rsvpTags" mode="rsvp" label="RSVP" />
                  <router-link to="/admin" @click.native="shown = false">
                    Add new author
                  </router-link>
                  <v-textarea
                    v-model="targetEvent.description"
                    label="Event Description"
                  />
                  <v-select
                    v-model="targetEvent.location"
                    :items="allLocations ? allLocations: []"
                    label="Location"
                    item-text="name"
                    item-title="name"
                    item-value="id"
                  />
                  <v-checkbox v-model="targetEvent.dateTimeTbd" label="Date TBD?"></v-checkbox>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
              <h2 class="mb-5">Date</h2>
              <v-checkbox v-model="outsideNormalDates" label="Outside normal event dates?"></v-checkbox>
              <v-date-picker
                v-if="
                  globalSettings &&
                  globalSettings.festivalDates &&
                  globalSettings.festivalDates[0] &&
                  globalSettings.festivalDates[1]
                "
                :disabled="targetEvent.dateTimeTbd"
                v-model="targetEvent.date"
                :min="!outsideNormalDates ? globalSettings.festivalDates[0] : ''"
                :max="!outsideNormalDates ? globalSettings.festivalDates[1] : ''"
              />
              <p v-else class="error--text">
                Please set the festival start and end dates from the <router-link to="/admin">Admin</router-link> page.
              </p>
            </v-col>
          </v-row>

          <v-row v-if="shown">
            <v-col>
              <h2 class="mb-5">Start</h2>
              <v-time-picker
                :disabled="targetEvent.dateTimeTbd"
                v-model="targetEvent.start"
                ampm-in-title
                :allowed-minutes="allowedStep"
              />
            </v-col>

            <v-col>
              <h2 class="mb-5">End</h2>
              <v-time-picker
                :disabled="targetEvent.dateTimeTbd"
                v-model="targetEvent.end"
                ampm-in-title
                :allowed-minutes="allowedStep"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="editing"
          @click="updateEvent"
          color="accent"
          block
          large
        >
          Update Event
        </v-btn>
        <v-btn
          v-else
          @click="addNewEvent"
          color="accent"
          block
          large
        >
          Add new event
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { addDoc, setDoc, collection, updateDoc, doc } from 'firebase/firestore'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import TagSelector from '@/components/TagSelector'

export default {
  components: {
    TagSelector
  },
  async mounted () {
    await this.authorsListener()

    this.$root.$on('showEventEditor', (targetEvent) => {
      if (targetEvent) {
        this.editing = true
        this.targetEvent = targetEvent
        this.eventSecrets = {
          tags: targetEvent.tags || [],
          rsvpTags: targetEvent.rsvpTags || []
        }
      } else {
        this.editing = false
        this.targetEvent = {
          date: this.globalSettings.festivalDates[0] || '2025-03-01'
        }
      }
      this.shown = true
    })
  },

  data: () => ({
    shown: false,
    editing: false,
    targetEvent: {},
    eventSecrets: {},
    outsideNormalDates: false
  }),

  computed: {
    ...mapState(['firestore', 'globalSettings']),
    ...mapGetters(['allAuthors', 'allLocations'])
  },

  methods: {
    ...mapMutations(['setGlobalNotification']),
    ...mapActions(['authorsListener']),

    allowedStep: m => m % 5 === 0, // Set minute selections in 5 minute increments

    /**
     * Attempt to add the new event to Firestore and display result notification.
     */
    async addNewEvent () {
      try {
        const res = await addDoc(collection(this.firestore, 'events'), this.targetEvent)
        const newEventId = res.id
        // Once the event is created, add its secrets subcollection
        // Tags must be in a protected subcollection because they expose other author IDs
        await setDoc(doc(this.firestore, `events/${newEventId}/secrets/tags`), this.eventSecrets)
        this.setGlobalNotification({ val: 'Added new event.' })
        this.dialogClose()
      } catch (err) {
        this.setGlobalNotification({ val: err.message, type: 'error' })
        console.log(err)
      }
    },

    /**
     * Update the existing Firestore doc
     */
    async updateEvent () {
      try {
        const eventCopy = JSON.parse(JSON.stringify(this.targetEvent))
        if (eventCopy.rsvpData) delete eventCopy.rsvpData
        if (eventCopy.id) delete eventCopy.id
        if (eventCopy.tags) delete eventCopy.tags
        if (eventCopy.rsvpTags) delete eventCopy.rsvpTags
        if (eventCopy.rsvps) delete eventCopy.rsvps

        await updateDoc(doc(this.firestore, 'events', this.targetEvent.id), eventCopy)
        await updateDoc(doc(this.firestore, `events/${this.targetEvent.id}/secrets/tags`), this.eventSecrets)
        this.setGlobalNotification({ val: 'Updated event.' })
        this.dialogClose()
      } catch (err) {
        this.setGlobalNotification({ val: err.message, type: 'error' })
        console.log(err)
      }
    },

    dialogClose () {
      this.shown = false
      this.targetEvent = {}
      this.eventSecrets = {}
    }
  },

  watch: {
    // Hide this modal if the route changes
    $route: function () {
      this.dialogClose()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  display: block;
  word-break: break-word !important;
}
.v-picker {
  width: 100%;
}
</style>
